import * as Action from "@actions/index";

interface InitialState {
  status: string;
  count: number;
  list: any;
  paginator: object | null;
  error: null;
}

const InitialState: InitialState = {
  status: "waiting",
  count: 0,
  list: null,
  paginator: null,
  error: null,
};

export default function NetworkingUser(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.User.SET_COUNT:
      return { ...state, count: action.data };
    case Action.Networking.User.SET_LIST:
      return { ...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.data) };
    case Action.Networking.User.SET_USER_CUTAWAY_STATUS:
      const newUsers = [...state.list];
      const userIndex = newUsers.findIndex(user => user.id === action.data.user_id);
      newUsers.splice(userIndex, 1, {
        ...newUsers[userIndex],
        cutaway: { ...newUsers[userIndex].cutaway, status: action.data.status }
      });
      return { ...state, list: newUsers };
    case Action.Networking.User.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.User.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Networking.User.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.User.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
