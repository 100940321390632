import React from "react";

const PreloaderPage: React.FC = () => {
  return (
    <div className="cssload-container">
      <div className="cssload-loading">
        <i />
        <i />
      </div>
    </div>
  );
};

export default PreloaderPage;
