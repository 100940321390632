export default class Profile {
  public static readonly SET_DATA = "PROFILE_SET_DATA";
  public static readonly SET_IS_CONTACTS_VALID = "PROFILE_SET_IS_CONTACTS_VALID";
  public static readonly SET_STATUS = "PROFILE_SET_STATUS";
  public static readonly SET_ERROR = "PROFILE_SET_ERROR";
  public static readonly SET_SUBSCRIBE_STATUS = "PROFILE_SET_SUBSCRIBE_STATUS";
  public static readonly CLEAR = "PROFILE_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: Profile.SET_DATA,
    };
  }

  public static setIsContactsValid(data: any) {
    return {
      data,
      type: Profile.SET_IS_CONTACTS_VALID,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Profile.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Profile.SET_ERROR,
    };
  }

  public static setSubscribeStatus(data: boolean) {
    return {
      data,
      type: Profile.SET_SUBSCRIBE_STATUS,
    }
  }

  public static clear() {
    return {
      type: Profile.CLEAR,
    };
  }
}
