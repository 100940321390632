import * as Action from "@actions/index";

interface InitialState {
  status: string;
  data: object | null;
  error: null;
}

const InitialState: InitialState = {
  status: "waiting",
  data: null,
  error: null,
};

export default function UserSelected(state = InitialState, action: any) {
  switch (action.type) {
    case Action.User.Selected.SET_DATA:
      return { ...state, data: action.data };
    case Action.User.Selected.SET_USER_CUTAWAY_STATUS:
      return { ...state, data: { ...state.data, cutaway: { ...state.data.cutaway, status: action.data } } };
    case Action.User.Selected.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Selected.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Selected.CLEAR:
      return { ...state, data: null };
    default:
      return state;
  }
}
