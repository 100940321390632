import * as Action from "@actions/index";

interface InitialState {
  status: string;
  data: object;
  error: null;
}

const InitialState = {
  status: "waiting",
  data: {},
  error: null,
};

export default function StanSelected(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Stand.Selected.SET_DATA:
      return { ...state, data: action.data };
    case Action.Stand.Selected.SET_USER_CUTAWAY_STATUS:
      const newBlocks = [...state.data.blocks];
      const blockIndex = newBlocks.findIndex(block => block.type === "users");
      const newUsers = [...newBlocks[blockIndex].data];
      const userIndex = newUsers.findIndex(user => user.id === action.data.user_id);
      newUsers.splice(userIndex, 1, {
        ...newUsers[userIndex],
        cutaway: { ...newUsers[userIndex].cutaway, status: action.data.status }
      });
      newBlocks.splice(blockIndex, 1, {
        ...newBlocks[blockIndex],
        data: newUsers,
      })

      return { ...state, data: { ...state.data, blocks: newBlocks } };
    case Action.Stand.Selected.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Stand.Selected.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Stand.Selected.CLEAR:
      return { ...state, data: null };
    default:
      return state;
  }
}
