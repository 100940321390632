import * as Action from "@actions/index";

interface InitialState {
  data: null;
  list: any;
}

const InitialState: InitialState = {
  data: null,
  list: null,
};

export default function NetworkingSearch(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.Search.SET_LIST:
      return { ...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.data) };
    case Action.Networking.Search.SET_USER_CUTAWAY_STATUS:
      const newUsers = [...state.list];
      const userIndex = newUsers.findIndex(user => user.id === action.data.user_id);
      newUsers.splice(userIndex, 1, {
        ...newUsers[userIndex],
        cutaway: { ...newUsers[userIndex].cutaway, status: action.data.status }
      });
      return { ...state, list: newUsers };
    case Action.Networking.Search.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
