import * as Action from "@actions/index";

const InitialState = {
  status: "loading",
  data: {},
  isContactsValid: null,
  error: "",
};

export default function Profile(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Profile.SET_DATA:
      return { ...state, data: action.data };
    case Action.Profile.SET_IS_CONTACTS_VALID:
      return { ...state, isContactsValid: action.data };
    case Action.Profile.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Profile.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Profile.SET_SUBSCRIBE_STATUS:
      return { ...state, data: { ...state.data, isEnabledSubscribeToAllWebinars: action.data } };
    case Action.Profile.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
}
