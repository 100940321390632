export default class MainPingpong {
  public static readonly SET_STATUS = "MAIN_PINGPONG_SET_STATUS";
  public static readonly SET_DATA = "MAIN_PINGPONG_SET_DATA";

  public static setStatus(status: boolean): { status: boolean; type: string } {
    return {
      status,
      type: MainPingpong.SET_STATUS,
    };
  }

  public static setData(data: string): { data: string; type: string } {
    return {
      data,
      type: MainPingpong.SET_DATA,
    };
  }
}
