import * as Action from "@actions/index";

const InitialState = {
  status: "loading",
  data: {},
  error: "",
};

export default function CompanyCurrent(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Company.Current.SET_DATA:
      return { ...state, data: action.data };
    case Action.Company.Current.SET_USER_CUTAWAY_STATUS:
      const newUsers = [...state.data.users];
      const userIndex = newUsers.findIndex(user => user.id === action.data.user_id);
      newUsers.splice(userIndex, 1, {
        ...newUsers[userIndex],
        cutaway: { ...newUsers[userIndex].cutaway, status: action.data.status }
      });

      return { ...state, data: { ...state.data, users: newUsers } };
    case Action.Company.Current.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Company.Current.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Company.Current.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
}
