export default class Schedule {
  public static readonly SET_SECTION_LIST = "SCHEDULE_SET_SECTION_LIST";
  public static readonly SET_LIST = "SCHEDULE_SET_LIST";
  public static readonly SET_MODIFIED_LIST = "SCHEDULE_SET_MODIFIED_LIST";
  public static readonly SET_SUBSCRIBE_MODIFIED_LIST = "SCHEDULE_SET_SUBSCRIBE_MODIFIED_LIST";
  public static readonly PATCH = "SCHEDULE_PATCH";
  public static readonly SET_STATUS = "SCHEDULE_SET_STATUS";
  public static readonly SET_ERROR = "SCHEDULE_SET_ERROR";
  public static readonly CLEAR = "SCHEDULE_CLEAR";

  public static setList(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_LIST,
    };
  }

  public static setSectionList(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_SECTION_LIST,
    };
  }

  public static setModifiedList(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_MODIFIED_LIST,
    };
  }

  public static setSubscribeModifiedList(): { type: string; } {
    return {
      type: Schedule.SET_SUBSCRIBE_MODIFIED_LIST,
    };
  }

  public static patch(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.PATCH,
    };
  }

  public static setStatus(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_STATUS,
    };
  }

  public static setError(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_ERROR,
    };
  }

  public static clear(): { type: string } {
    return {
      type: Schedule.CLEAR,
    };
  }
}
