export default class NetworkingSearch {
  public static readonly SET_LIST = "NETWORKING_SEARCH_SET_LIST";
  public static readonly SET_USER_CUTAWAY_STATUS = "NETWORKING_SEARCH_CUTAWAY_STATUS";
  public static readonly CLEAR = "NETWORKING_SEARCH_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: NetworkingSearch.SET_LIST,
    };
  }

  public static setUserCutawayStatus(data: { status: number, user_id: number }) {
    return {
      data,
      type: NetworkingSearch.SET_USER_CUTAWAY_STATUS,
    };
  }

  public static clear() {
    return {
      type: NetworkingSearch.CLEAR,
    };
  }
}
