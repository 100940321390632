import * as Action from "@actions/index";

const InitialState = {
  status: false,
  data: null,
};

export default (state = InitialState, action: { type: string; status: boolean; data: string }): any => {
  switch (action.type) {
    case Action.Main.Pingpong.SET_STATUS:
      return { ...state, status: action.status };
    case Action.Main.Pingpong.SET_DATA:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
